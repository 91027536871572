// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-en-js": () => import("./../../../src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-contact-index-zh-js": () => import("./../../../src/pages/contact/index.zh.js" /* webpackChunkName: "component---src-pages-contact-index-zh-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reports-index-en-js": () => import("./../../../src/pages/reports/index.en.js" /* webpackChunkName: "component---src-pages-reports-index-en-js" */),
  "component---src-pages-reports-index-zh-js": () => import("./../../../src/pages/reports/index.zh.js" /* webpackChunkName: "component---src-pages-reports-index-zh-js" */),
  "component---src-pages-tags-index-en-js": () => import("./../../../src/pages/tags/index.en.js" /* webpackChunkName: "component---src-pages-tags-index-en-js" */),
  "component---src-pages-tags-index-zh-js": () => import("./../../../src/pages/tags/index.zh.js" /* webpackChunkName: "component---src-pages-tags-index-zh-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-album-post-js": () => import("./../../../src/templates/album-post.js" /* webpackChunkName: "component---src-templates-album-post-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-gallery-list-js": () => import("./../../../src/templates/gallery-list.js" /* webpackChunkName: "component---src-templates-gallery-list-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

